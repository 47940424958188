@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.body {
	width: 100%;
	height: 100%;
	background: $off-white;
}

.wrapper {
	width: 100%;
	min-height: 100%;
	position: relative;
	overflow: hidden;
}

.header {
	background: $deep-green;
	position: relative;
	margin-bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-bottom: 5px;
	padding-top: 5px;
	height: 70px;
	transition:
		height 0.4s,
		min-height 0.8s;
	z-index: 5;
	min-height: 70px;
	width: 100%;

	@include desktop() {
		position: relative;
		height: auto;
		padding-top: 10px;
		width: 100%;
		min-height: 80px;
	}
}

.headerWrapper {
	position: relative;
	min-height: 70px;

	@include desktop() {
		min-height: 80px;

		&.bottomOffset {
			margin-bottom: 30px;
		}
	}
}

.bottomBorder {
	position: absolute;
	top: calc(100% - 2px);
	height: 25px;
	width: 100%;
	background-image: url("/images/header-bottom.svg");
	background-repeat: repeat;
	background-size: 20px 26px;
	transform: translateY(-13px);
	z-index: 2;

	&.bottomBorderInverse {
		height: 26px;
		//background-color:$deep-green;
		top: calc(100% - 12px);
		background-image: url("/images/letter-off-white-i.svg");
		background-repeat: repeat-x;
		background-position-y: 12px;
	}
	&::after {
		content: " ";
		clear: both;
		position: absolute;
	}
}

.headerLogoContainer {
	grid-column: 3 / span 2;
	text-align: center;

	@include tablet() {
		grid-column: 5 / span 4;
	}
}

.header__logo {
	text-indent: -9999px;
	overflow: hidden;
	width: 110px;
	display: inline-block !important;
	height: 60px;
	background-image: url(/images/logo.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	margin: 0 auto;
}

.mainContent {
	padding-bottom: 90px;
}

.maintenanceContent {
	margin: 50px auto;
	width: 300px;
	text-align: center;

	@include desktop() {
		width: 450px;
	}
}
