@charset 'UTF-8';
////////////////////////////////////////
// Utilities
////////////////////////////////////////
@use "utils/variables" as *;
@use "utils/mixins" as *;
@use "base/layout" as *;
@use "base/helpers" as *;
@use "base/reset" as *;
@use "base/typography" as *;
@use "base/datePicker" as *;

body {
	background: $off-white;
	font-family: $base-font-family;
}
